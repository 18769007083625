import { useEffect } from "react";
import { HubspostFormWrapper } from "./styles";
import { getPageLinkUrl } from "../../lib/utils";
import { useRouter } from "next/router";

export const HUBSPOT_FORM_SOURCE = {
  modal: "modal",
  landing: "landing",
};

const gtmEmailAdsEvent = (email) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "email_push",
    email,
  });
};

const gtmHubspotEvent = (action) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "hubspot_form",
    action,
  });
};

const gtmUETQActionEvent = (action) => {
  window.uetq = window.uetq || [];
  window.uetq.push("event", action, {});
};

const getRedirectPage = (form, data) => {
  const { defaultRedirect, redirects } = form;
  let redirectPage = defaultRedirect;
  let isHref = false;
  if (redirects?.length) {
    const activeRedirect = redirects.find((redirect) => {
      const { field, value } = redirect;
      return data[field] == value;
    });
    if (activeRedirect) {
      redirectPage = activeRedirect.href || activeRedirect.page;
      isHref = activeRedirect.href ? true : false;
    }
  }
  return { redirectPage, isHref };
};

const useHubspotForm = ({ id, form, source }) => {
  const router = useRouter();
  const containerId = `container${id}`;
  const onFormSubmitted = (form, data) => {
    const { redirectPage, isHref } = getRedirectPage(form, data);
    if (redirectPage) {
      const href = isHref ? redirectPage : getPageLinkUrl(redirectPage);
      router.push(href);
    }
  };
  const hubspotFormOptions = {
    formId: form.formId,
    region: form.region,
    portalId: form.portalId,
    target: `#${containerId}`,
    onBeforeFormInit: () => {
      gtmHubspotEvent("init");
    },
    onFormReady: () => {
      gtmHubspotEvent("ready");
    },
    onFormSubmit: ($form) => {
      const data = $form
        .serializeArray()
        .reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {});

      window.lastNameFieldHubspotForm = data.firstname;
      if (data.email) {
        gtmEmailAdsEvent(data.email);
      }

      if (source === HUBSPOT_FORM_SOURCE.landing) {
        gtmHubspotEvent("submit");
        if (data.lead_type === "family") {
          gtmHubspotEvent("submit_familias");
          gtmUETQActionEvent("submit_familias");
        } else {
          gtmHubspotEvent("submit_cuidador");
        }
      } else {
        jQuery('input[name="firstname"]')
          .val(data.firstname + data.phone)
          .change();
        gtmHubspotEvent("popup_submit_familias");
      }

      $(window).on("message", function (jqueryEvent) {
        const event = jqueryEvent.originalEvent;
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmitted"
        ) {
          gtmHubspotEvent("submited");
          $(this).off(jqueryEvent);
          onFormSubmitted(form, data);
        }
      });
    },
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    try {
      gtmHubspotEvent("call_create");
      hbspt.forms.create(hubspotFormOptions);
    } catch (error) {
      gtmHubspotEvent("error_call_create");
      console.error("Hubspot not defined");
    }
  }, []);

  return {
    containerId,
  };
};

export const HubspotForm = ({ id, form, source }) => {
  const { containerId } = useHubspotForm({ id, form, source });

  return <HubspostFormWrapper id={containerId} />;
};

export default HubspotForm;
