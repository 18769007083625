import styled from "styled-components";

export const HubspostFormWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
  fieldset {
    max-width: none !important;
  }
  legend {
    display: none;
  }
  input,
  select {
    font-size: var(--textS);
    line-height: var(--lineHeightS);
    width: 100% !important;
    border-radius: var(--spacingXs) !important;
    border: 1px solid var(--gray200) !important;
    padding: var(--spacingXs) var(--spacingS) !important;
    accent-color: var(--primary400) !important;

    @media screen and (min-width: 1128px) {
      height: var(--spacing2Xl) !important;
      font-size: var(--textM);
      line-height: var(--lineHeightM);
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"] {
    max-height: 15px !important;
    max-width: 15px !important;
  }

  .input {
    margin: 0 !important;
    &:focus-visible {
      outline: var(--gray) auto 1px !important;
    }
  }

  a {
    color: var(--gray);
    text-underline-offset: 2px;
    text-decoration: underline;
  }

  // Distribución de columnas por línea

  .form-columns-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .form-columns-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  // Estilo del wrapper de los campos

  .hs-form-field {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    float: none !important;
    margin-bottom: var(--spacingXs) !important;
    gap: var(--spacingXs) !important;

    > label {
      display: flex;
      font-size: var(--textS) !important;
      line-height: var(--lineHeightS) !important;
      padding-top: 0 !important;
      text-align: left;
      gap: 5px;
    }

    .hs-form-required {
      font-weight: bold;
      color: var(--cta800);
    }
  }

  // Estilo de los mensajes de error

  ul.hs-error-msgs {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
    height: auto !important;
    padding-inline-start: 0 !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    text-align: right !important;
    border: 0px;
    border-radius: 0px;
    li > {
      height: auto !important;
      label {
        color: var(--error500);
        font-size: var(--textXs) !important;
        line-height: var(--lineHeightXs) !important;
        padding: 0 !important;
      }
    }
  }

  .inputs-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-left: 0 !important;
    margin: 0 !important;
    list-style: none !important;
    font-size: var(--textS);

    > li {
      width: 100%;
    }
    &.hs-error-msgs > li {
      border: 0;
    }
  }

  // Estilo del radio input

  label.hs-form-radio-display {
    > span {
      display: flex;
      justify-content: center;
      font-size: 14px;
      outline: 1px solid var(--gray200);
      border-radius: var(--spacingXs);
      padding: 8px 8px 10px;
      background: transparent;
      cursor: pointer;

      &:hover,
      &:focus {
        background: var(--gray200);
      }

      @media screen and (min-width: 1128px) {
        font-size: 16px;
      }
    }
    input:checked + span {
      background: var(--secondary100);
      outline: 2px solid var(--cta400);
    }
  }

  // Estilo del checkbox de condiciones legales

  label.hs-form-booleancheckbox-display {
    display: flex;
    align-items: center;
    gap: 10px !important;
    cursor: pointer;
    border: 1px solid var(--gray200);
    border-radius: var(--spacingXs) !important;
    padding: 8px 15px 10px !important;
    background: transparent;
    cursor: pointer;
    &:hover,
    &:focus {
      background: var(--gray200);
    }
    input {
      width: auto !important;
    }
    span {
      margin-left: 0 !important;
      font-size: var(--textS) !important;
      line-height: var(--lineHeightS) !important;

      .hs-form-required {
        display: none;
      }
    }
  }

  .legal-consent-container {
    margin-top: 5px;
    input:focus {
      outline: none;
    }
  }

  // Estilo del botón submit

  .hs_submit {
    margin-top: var(--spacingM);
    input {
      font-size: var(--textM);
      line-height: var(--lineHeightM);
      height: var(--spacing2Xl) !important;
      width: 100% !important;
      padding: 0 var(--spacingM) !important;
      cursor: pointer;
      background-color: var(--primary800);
      color: white;
    }
  }
`;
